@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "Armavir01-Bold";
  src: url("./fonts/Armavir01-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transition-transform {
  transition: transform 0.7s ease-in-out;
}

.translate-x-full {
  transform: translateY(-100%);
}

.translate-x-0 {
  transform: translateY(0);
}
.range-input {
  appearance: none;
  background-color: white;
  height: 1px;
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.no-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.range-input::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}
/* styles/globals.css */

/* Add this if you want to customize the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@media (max-width: 768px) {
  .scroll-to-top {
    right: 20px;
  }
}
